import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Meta = ({ title, description, keywords, image }) => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta http-equiv="content-language" content="en"></meta>
        <meta name="description" content={description} />
        <link rel="icon" type="image/png" href="/img/icon.png" />
    </Helmet>
)

Meta.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    image: PropTypes.string,
}

Meta.defaultProps = {
    title: `Qantas`,
    description: `Card Companion`
}

export default Meta